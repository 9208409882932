<style scoped lang="scss">
.bg {
  @include background_color("bg");
}
.title_bg {
  @include background_color("title_bg");
}
.title_color {
  @include font_color("title_color");
}
.tip_color {
  @include font_color("tip_color");
}

.contain {
  width: 100%;
  height: 100%;
  position: relative;
  padding-top: 0.8rem;
  .searchInfo {
    width: 2rem;
    .searchInfoSpan {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      height: 0.8rem;
      margin-right: 0.1rem;
      img {
        width: 0.48rem;
        height: 0.48rem;
        margin: 0 0.16rem;
      }
    }
  }
  .info {
    display: flex;
    font-size: 0.3rem;
    height: 0.48rem;
    line-height: 0.48rem;
    justify-content: center;
    margin: 0.24rem 0.3rem 0.24rem 0.24rem;
    .total {
      color: #9798ab;
      font-size: 0.26rem;
    }
    .totalNumber {
      color: #fc7e36;
      font-weight: 600;
      font-size: 0.26rem;
      margin: 0 0.08rem;
    }
    .space {
      width: 1.28rem;
    }
    img {
      width: 0.48rem;
      height: 0.48rem;
      margin: 0 0.16rem;
    }
  }
  .active {
    color: #436eff !important;
    span {
      display: inline-block;
      width: 0.84rem;
      height: 0.06rem;
      border-radius: 0.06rem;
      background: #436eff;
      position: absolute;
      bottom: 0.04rem;
      left: 0;
      right: 0;
      margin: auto;
    }
  }
  .searchBack {
    text-align: center;
    display: inline-block;
    color: #7a7b92;
    width: 0.64rem;
    height: 0.64rem;
    position: absolute;
    top: 0.07rem;
    left: 0.24rem;
  }
  .tabLeft,
  .tabRight {
    width: 2rem;
    flex: 1;
    text-align: center;
    display: inline-block;
    color: #7a7b92;
    position: relative;
  }
  .myOrder {
    width: 100%;
    height: 0.8rem;
    line-height: 0.8rem;
    font-size: 0.3rem;
    margin: 0 auto;
    font-weight: 700;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    text-align: center;
    display: flex;
  }

  .web {
    width: 100%;
    height: 100%;
    overflow: auto;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
    -webkit-overflow-scrolling: touch;
    .pullRefreshScorll {
      min-height: calc(100% + 1px);
    }
    .orderList {
      width: 100%;
      padding-bottom: 0.2rem;

      @include background_color("bg");
      .orderListContent {
        // min-height: calc(100% + 1px)
      }
      .tip {
        height: 0.93rem;
        line-height: 0.93rem;
        /*margin-bottom: .30rem;*/
        font-size: 0.24rem;
        color: #aeb5ca;
        text-align: center;

        & + .order {
          margin-top: 0;
        }
      }
    }
  }
}
</style>

<template>
  <div class="contain bg" ref="contains">
    <div class="myOrder title_bg title_color" ref="refHeight1">
      <span style="flex: 1">
        <img
          v-show="role === '1'"
          class="searchBack"
          @click="handleBack"
          src="../../assets/imgs/goback.png"
          alt=""
        />
      </span>
      <span
        class="tabLeft"
        :class="tabType === 'left' ? 'active' : ''"
        @click="() => handleType('left')"
      >
        速运
        <span></span>
      </span>
      <span
        class="tabRight"
        :class="tabType === 'right' ? 'active' : ''"
        @click="() => handleType('right')"
      >
        快递
        <span></span>
      </span>
      <span class="searchInfo">
        <span class="searchInfoSpan" v-if="tabType === 'right'">
          <img
            class="searchPic"
            @click.stop="goSearch"
            src="../../assets/imgs/search.png"
            alt=""
          />
          <img
            v-if="privacyCheck.length > 0"
            class="searchPic"
            @click.stop="handleScreen"
            src="../../assets/imgs/searchBlue.png"
            alt=""
          />
          <img
            v-else
            class="selectPic"
            src="../../assets/imgs/select.png"
            alt=""
            @click.stop="handleScreen"
          />
        </span>
      </span>
    </div>
    <Tab @handleTab="handleTab" :defaultTab="queryStatus" :tabList="tabList" />
    <!-- 下拉刷新 -->
    <div class="web" ref="refHeight">
      <PullDownRefresh2 @refresh="refresh" class="pullRefreshScorll">
        <div class="orderList" ref="orderList">
          <div class="orderListContent">
            <div
              class="info"
              v-if="tabType === 'right' && orderList.length != 0"
            >
              <span class="total" v-if="queryStatus !== 3">
                共
                <span class="totalNumber">{{ total }}</span>
                单
              </span>
            </div>
            <!-- 空数据 -->
            <NoData v-if="emptyDataFlag" :msg="msg" />
            <!-- 有数据 -->
            <div v-if="!emptyDataFlag">
              <template v-if="tabType === 'left'">
                <OrderCard
                  :item="item"
                  v-for="item in orderList"
                  :key="item.orderCode"
                  @cancelOrder="handleCancelOrder"
                  @handleOpenDoor="handleOpenDoor"
                />
              </template>
              <template v-else>
                <NewOrderCard
                  :item="item"
                  v-for="item in orderList"
                  :key="item.orderCode"
                  @secondSend="handleSecondSendOrder"
                  @offlineDelivery="handleOfflineDelivery"
                  @toPhone="handlePhone"
                />
              </template>
            </div>
          </div>
        </div>
      </PullDownRefresh2>
    </div>
    <SearchList
      v-if="searchListFlag"
      :queryObject="queryObject"
      @handleSearchCancel="handleSearchCancel"
    />
    <PopSelect
      :styleObj="style"
      :triangle="triangle"
      :showFlag="showFlag"
      :selectList="selectList"
      :defaultValue="defaultValue"
      @handleSelect="handleSelectSet"
      @handleClose="handleScreen"
    />
    <!-- 评价 -->
    <Evaluation v-if="commentedShow" :show="commentedShow" />
    <Dialog
      v-if="showDialog"
      :dialogContent="dialogContent"
      :dialogTitle="dialogTitle"
      :dialogCancel="dialogCancel"
      :dialogEnter="dialogEnter"
      @handleOk="handleOk"
      @handleCancel="handleCancel"
    />
    <DialogAbn
      v-if="showDialogAbn"
      :abnList="abnList"
      :dialogTitle="dialogTitle"
      :dialogCancel="dialogCancel"
      :dialogEnter="dialogEnter"
      :confirmReturnCode="confirmReturnCode"
      @handleAbnOk="handleOk"
      @handleAbnCancel="handleCancel"
      @switchOption="switchOption"
    />
  </div>
</template>

<script>
import { nextTick } from "vue";
import moment from "moment";
import OrderCard from "./OrderCard.vue";
import NewOrderCard from "./NewOrderCard.vue";
import NoData from "./NoData.vue";
import Evaluation from "./Evaluation.vue";
import PullDownRefresh2 from "@/components/PullDownRefresh2";
import Tab from "./Tab.vue";
import Dialog from "@/components/Dialog.vue";
import Message from "vue-m-message";
import PopSelect from "./PopSelect.vue";
import SelectList from "./SearchList.vue";
import DialogAbn from "./DialogList.vue";
// import BScroll from "better-scroll";
import {
  deliveryPageList,
  cancelOrder,
  openDoor,
  secondDelivery,
  offlineDelivery,
  selectPageList,
  getAbnList,
  justOpenDoor,
} from "@/api/api";
import { leftList, rightList, selectList } from "@/config/index";
import SearchList from "./SearchList.vue";
export default {
  name: "OrderList",
  components: {
    OrderCard,
    NewOrderCard,
    NoData,
    Evaluation,
    PullDownRefresh2,
    PopSelect,
    Tab,
    Dialog,
    SelectList,
    SearchList,
    DialogAbn,
  },
  data() {
    return {
      style: {
        box: {
          position: "absolute",
          top: "0.9rem",
          left: "4.3rem",
        },
        triangle: {
          position: "absolute",
          top: "0.79rem",
          left: "6.75rem",
        },
      },
      height: 0,
      selectList: selectList,
      defaultValue: 2,
      selectValue: "全部",
      msg: "暂无订单",
      orderList: [],
      total: 0,
      privacyCheck: [],
      triangle: "right",
      emptyDataFlag: false,
      commentedShow: false,
      showFlag: false,
      scrollTop: 0,
      queryStatus: 0, // 0-全部 1-进行中  2-已取消 4-已完成  0-待配送 1-配送中  2-已完成 3-异常件
      pageNum: 1,
      pageSize: 100,
      showDialog: false,
      dialogType: "cancelOrder",
      dialogContent: "确认取消订单吗？",
      dialogCancel: "取消",
      dialogEnter: "确定",
      cancelOrderCode: "", //  取消订单号
      secondSendOrderCode: "", // 二次配送单号
      openOrderCode: "",
      confirmReturnCode: "", //退库原因选项的code
      confirmReturnDesc: "", //退库原因描述
      orderDetail: {},
      openVin: "",
      timer: null,
      tabList: leftList,
      tabType: "left",
      leftQueryStatus: 0,
      rightQueryStatus: 0,
      searchListFlag: false,
      queryObject: {
        queryStatus: this.queryStatus,
        privacyCheck: JSON.stringify(this.privacyCheck),
      },
      abnList: [],
      showDialogAbn: false,
      role: "1", //运营版 2货主版
      type: "left",
    };
  },

  computed: {
    selectAll() {
      const len = Object.keys(this.selectedMap).length;
      return len && len === this.orderList.length;
    },
    selectedCount() {
      return Object.keys(this.selectedMap).length;
    },
  },

  mounted: function () {},

  created: function () {
    this.queryStatus = 0;
    this.height = window.innerHeight;
    this.type = this.tabType = this.$route.query.tabType || "left";
    this.$loadingCircle.start();
    this.role = this.$route.query.role;
    if (this.tabType === "right") {
      this.queryStatus = Number(this.$route.query.queryStatus);
      this.privacyCheck = JSON.parse(this.$route.query.privacyCheck);
      if (this.privacyCheck.length > 0) {
        this.defaultValue = Number(this.privacyCheck[0]);
      } else {
        this.defaultValue = 2;
      }

      this.getSelectPageList();
      this.timer = setInterval(() => {
        this.getSelectPageList();
      }, 60000);
    } else {
      this.getOrderList();
      this.timer = setInterval(() => {
        this.getOrderList();
      }, 60000);
    }
    // this.$nextTick(() => {
    //   this.init();
    // });
    // this.stopTouchMove();
  },
  beforeDestroy() {
    clearInterval(this.timer);
    this.timer = null;
  },
  methods: {
    handleSearchCancel() {
      this.searchListFlag = false;
    },
    handleBack() {
      window.location.href = "neolix://interaction";
    },
    async stopTouchMove() {
      await nextTick();
      const layerBox = this.$refs.orderList;
      var targetY = null;
      layerBox.addEventListener("touchstart", function (e) {
        //clientY-客户区坐标Y 、pageY-页面坐标Y
        targetY = Math.floor(e.targetTouches[0].clientY);
      });
      layerBox.addEventListener(
        "touchmove",
        function (e) {
          // 检测可滚动区域的滚动事件，如果滑到了顶部或底部，阻止默认事件
          var NewTargetY = Math.floor(e.targetTouches[0].clientY), //本次移动时鼠标的位置，用于计算
            sTop = layerBox.scrollTop, //当前滚动的距离
            sH = layerBox.scrollHeight, //可滚动区域的高度
            lyBoxH = layerBox.clientHeight; //可视区域的高度
          if (sTop <= 0 && NewTargetY - targetY > 0 && "鼠标方向向下-到顶") {
            console.log("条件1成立：下拉页面到顶");
            e.preventDefault();
          } else if (
            sTop >= sH - lyBoxH &&
            NewTargetY - targetY < 0 &&
            "鼠标方向向上-到底"
          ) {
            console.log("条件2成立：上翻页面到底");
            e.preventDefault();
          } else {
            console.log("ccccc");
          }
        },
        false
      );
    },

    handleClosePop() {
      this.showFlag = !this.showFlag;
    },
    handleScreen() {
      this.showFlag = !this.showFlag;
    },
    handleSelectSet(data) {
      this.selectValue = data.value;
      if (data.key === 2) {
        this.privacyCheck = [];
      } else {
        this.privacyCheck = [];
        this.privacyCheck.push(data.key);
      }

      this.placeholderValue = "请输入" + data.value;
      this.showFlag = !this.showFlag;
      if (this.rightQueryStatus !== 3) {
        this.getSelectPageList();
      }
    },
    getToken() {
      return sessionStorage.getItem("_token");
    },
    getUserId() {
      return sessionStorage.getItem("_userId");
    },
    goSearch() {
      this.queryObject = {
        queryStatus: this.queryStatus,
        privacyCheck: JSON.stringify(this.privacyCheck),
      };
      this.searchListFlag = true;
    },
    handleType(type) {
      this.type = type;
      this.orderList = [];
      this.$loadingCircle.start();
      if (type === "right") {
        this.queryStatus = this.rightQueryStatus;
        this.getSelectPageList();
        clearInterval(this.timer);
        this.timer = setInterval(() => {
          this.getSelectPageList();
        }, 60000);
      } else {
        this.queryStatus = this.leftQueryStatus;
        this.tabList = leftList;
        this.getOrderList();
        clearInterval(this.timer);
        this.timer = setInterval(() => {
          this.getOrderList();
        }, 60000);
      }
      this.tabType = type;
    },
    getSelectPageList(done) {
      const params = {
        privacyCheck: this.privacyCheck,
        queryStatus: this.queryStatus,
        userId: this.getUserId(),
        vinId: "",
        expressNo: "",
        rcvStationName: "",
        rcvPhone: "",
        pageSize: this.pageSize,
        pageNo: 1,
      };
      // if (this.queryStatus === 2) {
      params.endDate = moment().locale("zh-cn").format("YYYY-MM-DD");
      params.startDate = moment().add(-6, "d").format("YYYY-MM-DD");
      // }
      selectPageList({
        ...params,
      })
        .then((res) => {
          done && done();
          if (this.queryStatus === 3) {
            return false;
          }
          if (this.type !== "right") return; // 解决点击快的时候渲染出错的问题
          this.orderList = res.list;
          this.total = res.total;
          rightList[rightList.length - 1].abnormalCount = res.abnormalCount;
          this.tabList = rightList;
          if (this.orderList.length > 0) {
            this.emptyDataFlag = false;
          } else {
            this.emptyDataFlag = true;
          }
          this.orderList.map((item) => {
            if (item.rcvPhone) {
              item.encryptionUserPhone = item.rcvPhone;
            }
            switch (item.expressStatus) {
              case 0:
                item.color = "cFC7E36";
                break;
              case 1:
                item.color = "c436EFF";
                break;
              case 3:
                item.color = "c00CB95";
                break;
              case 4:
                item.color = "cFF391B";
                break;
              default:
                item.color = "c436EFF";
                break;
            }
          });
        })
        .finally(() => {
          this.tabList = rightList;
          this.$loadingCircle.end();
        })
        .catch((err) => {
          this.orderList = [];
          this.emptyDataFlag = true;
        });
    },
    getOrderList(done) {
      deliveryPageList({
        userId: sessionStorage.getItem("_userId"),
        queryStatus: this.queryStatus,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
      })
        .then((res) => {
          done && done();
          if (this.type !== "left") return; // 解决点击快的时候渲染出错的问题
          this.orderList = res.list;
          if (this.orderList.length > 0) {
            this.emptyDataFlag = false;
          } else {
            this.emptyDataFlag = true;
          }
          this.orderList.map((item) => {
            if (item.rcvPhone) {
              item.encryptionUserPhone =
                item.rcvPhone.substring(0, 3) +
                "****" +
                item.rcvPhone.substring(7);
            }
            switch (item.orderStatus) {
              case 2:
                item.color = "c9798AB";
                break;
              case 4:
                item.color = "c000000";
                break;
              case 14:
                item.color = "cFC7E36";
                break;
              case 15:
                item.color = "c26C575";
                break;
              default:
                item.color = "c436EFF";
                break;
            }
          });
        })
        .finally(() => {
          this.$loadingCircle.end();
        })
        .catch((err) => {
          this.orderList = [];
          this.emptyDataFlag = true;
        });
    },
    getAbnList(value) {
      // 暂时参数没有
      getAbnList({
        sheetNo: value.expressNo,
        stationId: value.rcvStationId,
        companyCode: value.expressCompanyCode,
        // sheetNo: "1292766926655",
        // stationId: "1",
        // companyCode: "1",
      })
        .then((res) => {
          // this.showDialog = false;
          this.abnList = res;
          this.abnList.map((v, n) => {
            v.check = false;
          });
          this.$forceUpdate();
        })
        .finally(() => {
          // this.showDialog = false;
          this.getSelectPageList();
        });
    },
    handleTab(data) {
      this.queryStatus = data.key;
      this.$refs.refHeight.scrollTop = 0;
      if (this.tabType === "left") {
        this.leftQueryStatus = data.key;
        this.getOrderList();
      } else {
        this.rightQueryStatus = data.key;
        if (data.key === 3) {
          this.orderList = [];
          this.total = 0;
          this.emptyDataFlag = true;
          this.msg = "请先通过右上角搜索订单";
          // 展示异常件的样式
        } else {
          this.msg = "暂无订单";
          this.getSelectPageList();
        }
      }
    },
    // 打电话
    handlePhone(item) {
      let encryptionUserPhone = item.encryptionUserPhone;
      if (encryptionUserPhone.includes("-")) {
        const phoneItem = encryptionUserPhone.split("-");
        this.phoneNumber = phoneItem[0];
        this.dialogCancel = "暂不拨打";
        this.dialogEnter = "确定拨打";
        this.dialogTitle = "确定拨打电话吗？";
        this.dialogContent = "请在电话拨通后拨转接号" + phoneItem[1];
        this.showDialog = true;
        this.dialogType = "telphone";
      } else {
        this.phoneNumber = encryptionUserPhone;
        this.telphone();
      }
    },

    // 二次配送
    handleSecondSendOrder(item) {
      this.secondSendOrderCode = item;
      this.dialogTitle = "确定二次配送该快递吗？";
      this.dialogContent =
        "确定后，订单状态将恢复为“待配送”，可为车辆重新下发配送任务";
      this.dialogCancel = "取消";
      this.dialogEnter = "确定";
      this.showDialog = true;
      this.dialogType = "secondSendOrder";
    },

    // 线下配送
    handleOfflineDelivery(data) {
      this.orderDetail = data;
      this.offlineDeliveryOrderCode = data.packageNo;
      this.vinId = data.vehicleVin;
      this.cabinCode = data.cabinCode;
      // 0 - 摩西系统 1 - 小兵系统
      if ([0, 1].includes(data.expressSource)) {
        // if (true) {
        this.showDialog = true;
        this.dialogTitle = "线下配送";
        this.dialogContent = "是否执行【驿站退库】操作？";
        this.dialogCancel = "仅取出，不退库";
        this.dialogEnter = "退库";
        // this.dialogType = "expressOfflineDelivery";

        if (data.expressSource === 0) {
          //摩西
          //如果是摩西则不拉取退库原因，点击退库就直接退库不传原因
          this.dialogType = "confirmReturn";
        } else {
          this.dialogType = "expressOfflineDelivery";
          //如果是小兵系统拉取退库原因
          this.getAbnList(data);
        }
      } else {
        this.dialogTitle = "确定线下配送该快递吗？";
        this.dialogContent =
          "确定后，格口将自动打开，请取出包裹通过其他线下方式完成配送";
        this.showDialog = true;
        this.dialogType = "offlineDelivery";
      }
    },

    // 取消订单
    handleCancelOrder(orderCode) {
      this.cancelOrderCode = orderCode;
      this.dialogContent = "确定取消订单吗？";
      this.showDialog = true;
      this.dialogType = "cancelOrder";
    },

    // 打开柜门
    handleOpenDoor(data) {
      this.openOrderCode = data.orderCode;
      this.openVin = data.vehicleVin;
      this.dialogContent = "确定打开柜门吗？";
      this.showDialog = true;
      this.dialogType = "openDoor";
    },

    handleOk() {
      this.showDialog = false;
      if (this.dialogType === "cancelOrder") {
        this.cancelOrder();
      } else if (this.dialogType === "openDoor") {
        this.openDoor();
      } else if (this.dialogType === "secondSendOrder") {
        this.secondSendOrder();
      } else if (this.dialogType === "offlineDelivery") {
        this.offlineDelivery();
      } else if (this.dialogType === "telphone") {
        this.telphone();
      } else if (this.dialogType === "expressOfflineDelivery") {
        this.showDialogAbn = true;
        this.dialogTitle = "请选择退库原因";
        // this.dialogContent = "是否执行【驿站退库】操作？";
        this.dialogCancel = "取消";
        this.dialogEnter = "确定退库";
        this.dialogType = "confirmReturn";
      } else if (this.dialogType === "confirmReturn") {
        console.log("dialogType:", this.dialogType);
        console.log("确定退库");
        this.takeOut(2);
      }
    },

    handleCancel(flag = false) {
      console.log("flag:", flag, this.dialogType);
      if (!flag && this.dialogType === "expressOfflineDelivery") {
        console.log("仅取出，不退库 expressOfflineDelivery");
        // 仅取出，不退库
        this.takeOut(1);
      } else if (
        !flag &&
        this.dialogType === "confirmReturn" &&
        this.orderDetail.expressSource === 0
      ) {
        console.log("仅取出，不退库   confirmReturn");
        this.takeOut(1);
      } else {
        this.showDialog = false;
        this.showDialogAbn = false;
        this.confirmReturnCode = "";
      }
    },

    //切换退库原因
    switchOption(i) {
      this.abnList.map((v, n) => {
        if (n === i) {
          v.check = true;
          this.confirmReturnCode = v.code;
          this.confirmReturnDesc = v.desc;
        } else {
          v.check = false;
        }
      });
      this.abnList = [...this.abnList];
    },

    // 取出-退库/不退库
    takeOut(type) {
      console.log("type------", type);
      justOpenDoor({
        vin: this.orderDetail.vehicleVin,
        packageNo: this.orderDetail.packageNo, //包裹订单号
        abnCode: this.confirmReturnCode, //异常原因code
        abnDesc: this.confirmReturnDesc, //异常原因
        expressSource: this.orderDetail.expressSource, //面单来源 0--摩西  1--小兵
        operationType: type, //1--仅取出，不退库 2--退库
      })
        .then((res) => {
          // this.showDialog = false;
          type === 2 && (this.confirmReturnCode = ""); //置灰确定按钮
          Message({
            title: "操作成功！",
            type: "success",
            collapsable: false,
          });
          // console.log(`type == 1 ? '取出不退库成功' : '退库成功'`, res);
        })
        .finally(() => {
          this.showDialog = false;
          this.showDialogAbn = false;
          this.getSelectPageList();
        });
    },

    // 仅取出，不退库
    // sendOnlyOpenDoor() {
    //   justOpenDoor({
    //     packageNo: this.secondSendOrderCode,
    //     operationType: 1,//1--仅取出，不退库 2--退库
    //   })
    //     .then((res) => {
    //       this.showDialog = false;
    //       console.log(1111, res);
    //     })
    //     .finally(() => {
    //       this.showDialog = false;
    //       this.getSelectPageList();
    //     });
    // },

    // 二次配送订单
    secondSendOrder() {
      secondDelivery({
        id: this.secondSendOrderCode.id,
        // packageNo: this.secondSendOrderCode,
      })
        .then((res) => {
          this.showDialog = false;
        })
        .finally(() => {
          this.getSelectPageList();
        })
        .catch((err) => {
          // Message({
          //   title: err.msg,
          //   type: "success",
          //   collapsable: false,
          // });
        });
    },
    // 拨打电话
    telphone() {
      window.location.href = "tel:" + this.phoneNumber;
    },

    // 线下配送
    offlineDelivery(data) {
      console.log("订单数据：", data);
      offlineDelivery({
        packageNo: this.offlineDeliveryOrderCode,
        vin: this.vinId,
        cabinCode: this.cabinCode,
      })
        .then((res) => {
          this.showDialog = false;
        })
        .finally(() => {
          this.getSelectPageList();
        })
        .catch((err) => {});
    },

    // 取消订单
    cancelOrder() {
      this.$loadingCircle.start();
      cancelOrder({
        orderCode: this.cancelOrderCode,
      })
        .then((res) => {
          this.showDialog = false;
          Message({
            title: "订单已取消，即将退款！",
            type: "success",
            collapsable: false,
          });
          this.getOrderList();
        })
        .catch((err) => {});
    },

    // 打开柜门
    openDoor() {
      this.$loadingCircle.start();
      openDoor({
        orderCode: this.openOrderCode,
        vin: this.openVin,
      })
        .then((res) => {
          this.showDialog = false;
          this.$router.push({
            path: "/openDoor",
            query: {
              orderCode: this.openOrderCode,
              vin: this.openVin,
              countdownTime: res.countdownTime,
            },
          });
        })
        .catch((err) => {
          // Message({
          //   title: err.msg,
          //   type: "success",
          //   collapsable: false,
          // });
        });
    },

    refresh(done) {
      if (this.tabType === "left") {
        this.getOrderList(done);
      } else {
        this.getSelectPageList(done);
      }
    },
    handleScroll() {
      let scrollTop = this.$refs.refHeight.getBoundingClientRect().top;
      let oneHeight = this.$refs.refHeight.offsetHeight;
      if (-oneHeight >= scrollTop) {
        this.utils.setTitle("我的订单");
      } else {
        this.utils.setTitle("");
      }
    },
  },
};
</script>
